import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  Card,
  Divider,
  Group,
  Modal,
  Paper,
  Stack,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import dayjs from "dayjs";
import ProjectCard from "../components/Tasks/Cards";
import CreateProject from "../components/Tasks/Cards/CreateTask";
import { useDisclosure } from "@mantine/hooks";
import Classes from "./Styling/Projects.module.scss";
import { Project, Workspace } from "../types";
import { IconPlus } from "@tabler/icons-react";

export interface Task {
  _id: string;
  title: string;
  assignees: string[];
  content: string;
  status: string;
  dueDate: string;
  createdAt: string;
  comments: string[];
  workspaceId: string;
  workspaceTitle: string;
  projectId: string;
}

export const MemoizedProjectCard = React.memo(ProjectCard);

const Projects = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null
  );
  const { workspaceId, projectId } = useParams();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<Project | null>(null);
  const [workspace, setWorkspace] = useState<Workspace | null>(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `/api/workspaces/${workspaceId}/projects/${projectId}`
        );
        setProject(response.data);
      } catch (error) {
        console.error("Failed to fetch project", error);
      }
    };

    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `/api/workspaces/${workspaceId}/projects/${projectId}/tasks`
        );
        setTasks(response.data);
      } catch (error) {
        console.error("Failed to fetch tasks", error);
      }
    };

    fetchProject();
    fetchTasks();
  }, [workspaceId, projectId]);

  const handleTaskCreate = async () => {
    const currentDate = new Date();
    const dueDate = new Date(currentDate.setDate(currentDate.getDate() + 7));

    const newTask = {
      title: "New task",
      assignees: [],
      comments: [],
      dueDate: dayjs(dueDate).toISOString(), // Ensure correct format
      content: "The start of something great",
      status: "To Do",
    };

    try {
      const response = await axios.post(
        `/api/workspaces/${workspaceId}/projects/${projectId}/tasks`,
        newTask
      );
      const createdTask = response.data;
      setTasks((prevTasks) => [...prevTasks, createdTask]);
      setSelectedTask(createdTask);
      open();
    } catch (error) {
      console.error("Failed to create task", error);
    }
  };

  const handleTaskEdit = (updatedTask: Task) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task._id === updatedTask._id ? updatedTask : task
      )
    );

    try {
      axios.put(
        `/api/workspaces/${workspaceId}/projects/${projectId}/tasks/${updatedTask._id}`,
        {
          title: updatedTask.title,
          assignees: updatedTask.assignees,
          content: updatedTask.content,
          status: updatedTask.status,
          dueDate: new Date(updatedTask.dueDate).toISOString(),
        }
      );
    } catch (error) {
      console.error("Failed to update task", error);
    }
  };

  const handleEditClick = (task: Task) => {
    setSelectedTask(task);
    open();
  };

  const handleTaskDelete = async (taskId: string) => {
    try {
      await axios.delete(
        `/api/workspaces/${workspaceId}/projects/${projectId}/tasks/${taskId}`
      );
      // Update the tasks state directly without refetching
      setTasks((prevTasks) => prevTasks.filter((task) => task._id !== taskId));
    } catch (error) {
      console.error("Failed to delete task", error);
    }
  };

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const draggedTaskIndex = tasks.findIndex(
      (task) => task._id === draggableId
    );

    if (draggedTaskIndex === -1) {
      console.error("Dragged task not found");
      return;
    }

    const updatedTasks = Array.from(tasks);
    const [draggedTask] = updatedTasks.splice(draggedTaskIndex, 1);
    draggedTask.status = destination.droppableId;
    updatedTasks.splice(destination.index, 0, draggedTask);

    setTasks(updatedTasks);

    try {
      await axios.put(
        `/api/workspaces/${workspaceId}/projects/${projectId}/tasks/${draggedTask._id}`,
        {
          title: draggedTask.title,
          assignees: draggedTask.assignees,
          content: draggedTask.content,
          status: draggedTask.status,
          dueDate: new Date(draggedTask.dueDate).toISOString(),
        }
      );
    } catch (error) {
      console.error("Failed to update task status", error);
    }
  };

  const renderTaskCards = (status: string) => {
    const filteredTasks = tasks
      .filter((task) => task.status === status)
      .sort(
        (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
      );

    return (
      <Droppable droppableId={status}>
        {(provided) => (
          <Stack
            gap={"xs"}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ minHeight: "50px" }}>
            {filteredTasks.map((task, index) => (
              <Draggable key={task._id} draggableId={task._id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <MemoizedProjectCard
                      project={task}
                      onEditClick={handleEditClick}
                      onDelete={handleTaskDelete}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {filteredTasks.length === 0 && (
              <Stack h={50} align="center" justify="center">
                <Text size="sm" ta="center" c="dimmed">
                  No tasks
                </Text>
              </Stack>
            )}
          </Stack>
        )}
      </Droppable>
    );
  };

  const items = [
    { title: "Return", href: `/${workspaceId}/projects` },
    { title: project?.title, href: "#" },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <Tabs defaultValue={"all"}>
      <Stack className={Classes.taskWindow} gap={0} h="calc(100vh - 60px)">
        <Modal
          centered
          closeOnClickOutside
          withOverlay
          size={"92rem"}
          overlayProps={{
            blur: 10,
          }}
          opened={opened}
          onClose={() => {
            close();
            setSelectedTask(null);
          }}
          withCloseButton={false}
          radius={"lg"}
          transitionProps={{ transition: "fade", duration: 200 }}>
          <CreateProject
            task={selectedTask}
            onTaskCreate={(newTask) => {
              setTasks((prevTasks) => [...prevTasks, newTask]);
              setSelectedTask(newTask);
            }}
            onTaskEdit={handleTaskEdit}
          />
        </Modal>
        <Group px="xl" py="lg" justify="space-between">
          <Title order={4}>{project?.title}</Title>
          <ActionIcon
            onClick={handleTaskCreate}
            size="md"
            title="Create new workspace">
            <IconPlus size={20} stroke={1} />
          </ActionIcon>
        </Group>

        <Stack gap={0} justify="stretch">
          <Divider />
          <Stack
            p={"xl"}
            justify="stretch"
            align="stretch"
            mah={"100%"}
            miw={"100%"}
            gap="xl">
            <Breadcrumbs>{items}</Breadcrumbs>

            <Group h={"100%"} w={"100%"} align="left" wrap="nowrap">
              <DragDropContext onDragEnd={onDragEnd}>
                <Paper
                  radius={"md"}
                  style={{
                    overflow: "hidden",
                  }}
                  h={"fit-content"}
                  withBorder
                  p={0}
                  miw={"22%"}>
                  <Stack gap={0}>
                    <Card pos={"sticky"}>
                      <Group>
                        <Paper h={6} w={6} bg={"gray.6"} />
                        <Title order={5}>To Do</Title>
                      </Group>
                    </Card>
                    <Divider />
                  </Stack>
                  <Stack p={"sm"}>{renderTaskCards("To Do")}</Stack>
                </Paper>

                <Paper
                  radius={"md"}
                  style={{
                    overflow: "hidden",
                  }}
                  h={"fit-content"}
                  withBorder
                  p={0}
                  miw={"22%"}>
                  <Stack gap={0}>
                    <Card pos={"sticky"}>
                      <Group>
                        <Paper h={6} w={6} bg={"orange.6"} />
                        <Title order={5}>In Progress</Title>
                      </Group>
                    </Card>
                    <Divider />
                  </Stack>
                  <Stack p={"sm"}>{renderTaskCards("In Progress")}</Stack>
                </Paper>

                <Paper
                  radius={"md"}
                  style={{
                    overflow: "hidden",
                  }}
                  h={"fit-content"}
                  withBorder
                  p={0}
                  miw={"22%"}>
                  <Stack gap={0}>
                    <Card pos={"sticky"}>
                      <Group>
                        <Paper h={6} w={6} bg={"indigo.6"} />
                        <Title order={5}>In Review</Title>
                      </Group>
                    </Card>
                    <Divider />
                  </Stack>
                  <Stack p={"sm"}>{renderTaskCards("In Review")}</Stack>
                </Paper>

                <Paper
                  radius={"md"}
                  style={{
                    overflow: "hidden",
                  }}
                  h={"fit-content"}
                  withBorder
                  p={0}
                  miw={"22%"}>
                  <Stack gap={0}>
                    <Card pos={"sticky"}>
                      <Group>
                        <Paper h={6} w={6} bg={"teal.6"} />
                        <Title order={5}>Completed</Title>
                      </Group>
                    </Card>
                    <Divider />
                  </Stack>
                  <Stack p={"sm"}>{renderTaskCards("Completed")}</Stack>
                </Paper>
              </DragDropContext>
            </Group>
          </Stack>

          <Tabs.Panel value="taskId">
            <CreateProject
              task={selectedTask}
              onTaskCreate={(newTask) => {
                setTasks((prevTasks) => [...prevTasks, newTask]);
                setSelectedTask(newTask);
              }}
              onTaskEdit={handleTaskEdit}
            />
          </Tabs.Panel>
        </Stack>
      </Stack>
    </Tabs>
  );
};

export default Projects;
