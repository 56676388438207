import { createTheme, Input, InputWrapper } from "@mantine/core";

// Define your custom theme
const customTheme = createTheme({
  shadows: {
    glow: "-4px -4px 10px 0px rgba(0, 255, 178, 0.05), 6px 6px 10px 0px rgba(109, 69, 222, 0.05)",
  },
  colors: {
    dark: [
      "#f4f4f5", //titles
      "#b4b9c5",
      "#666B74", //Text
      "#666B74", //placeholders
      "#27272A", //borders
      "#14151a", //inputs
      "#18181B", //cards/paper
      "#0E0E11", //bg_
      "#18181B", //avatar bg
      "#18181B",
    ],
  },
  primaryColor: "teal",
  defaultRadius: "md",

  components: {
    Paper: {
      defaultProps: {
        padding: "xl",
        shadow: "none",
      },
    },
    Title: {
      defaultProps: {
        fw: 550,
      },
    },
    Text: {
      defaultProps: {
        size: "lg",
      },
    },
    Card: {
      defaultProps: {
        shadow: "none",
      },
    },
    Anchor: {
      defaultProps: {
        c: "dimmed",
      },
    },
    TextInput: {
      defaultProps: {
        size: "md",
        labelProps: {
          mb: 8,
          fw: 450,
        },
      },
    },
    InputWrapper: {
      defaultProps: {
        size: "lg",
        labelProps: {
          mb: 8,
          fw: 450,
        },
      },
    },
    Textarea: {
      defaultProps: {
        size: "md",
        labelProps: {
          mb: 8,
          fw: 450,
        },
      },
    },
    MultiSelect: {
      defaultProps: {
        size: "md",
        labelProps: {
          mb: 8,
          fw: 500,
        },
      },
    },
    PasswordInput: {
      defaultProps: {
        size: "md",
        labelProps: {
          mb: 8,
          fw: 500,
        },
      },
    },
    NumberInput: {
      defaultProps: {
        size: "md",
        labelProps: {
          mb: 8,
          fw: 500,
        },
      },
    },
    NavLink: {
      defaultProps: {
        py: 6,
        px: 12,
      },
    },
    Button: {
      defaultProps: {
        radius: "md",
      },
    },
  },
});

export default customTheme;
