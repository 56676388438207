import React, { useState, useEffect } from "react";
import {
  Stack,
  Textarea,
  Button,
  Group,
  Title,
  Divider,
  Container,
  Paper,
  TextInput,
  PasswordInput,
  TagsInput,
  ScrollArea,
} from "@mantine/core";
import axios from "axios"; // Import axios
import { useAuth } from "../../contexts/AuthContext";
import Classes from "./index.module.scss";
import SubscribeButton from "../Payments/Subscribe";
import CancelSubscriptionButton from "../Payments/Cancel";

interface OnboardingFormProps {
  onComplete: (data: {
    first_name: string;
    last_name: string;
    email: string;
    password?: string;
    skills: string[];
    interests: string[];
    bio: string;
  }) => void;
  onSkip: () => void;
}

const EditUserDetails: React.FC<OnboardingFormProps> = ({
  onComplete,
  onSkip,
}) => {
  const { currentUser } = useAuth(); // Get the current user
  const [firstName, setFirstName] = useState<string>(""); // State for first name
  const [lastName, setLastName] = useState<string>(""); // State for last name
  const [email, setEmail] = useState<string>(""); // State for email
  const [password, setPassword] = useState<string>(""); // State for password
  const [skills, setSkills] = useState<string[]>([]); // State for skills
  const [interests, setInterests] = useState<string[]>([]); // State for interests
  const [bio, setBio] = useState<string>(""); // State for bio
  const [skillOptions, setSkillOptions] = useState<string[]>([
    // predefined skill options
  ]);
  const [interestOptions, setInterestOptions] = useState<string[]>([
    // predefined interest options
  ]);

  // Fetch current user data when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_API}/api/users/${currentUser._id}`
        );
        const userData = response.data;
        setFirstName(userData.first_name || "");
        setLastName(userData.last_name || "");
        setEmail(userData.email || "");
        setSkills(userData.skills || []);
        setInterests(userData.interests || []);
        setBio(userData.bio || "");
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUserData();
  }, [currentUser._id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Make the PUT request to update the user's profile
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_API}/api/users/${currentUser._id}`,
        {
          first_name: firstName,
          last_name: lastName,
          email,
          ...(password && { password }), // Include password only if it's set
          skills,
          interests,
          bio,
          onboardingCompleted: true,
        }
      );

      onComplete(response.data); // Pass the updated user data to the onComplete callback
    } catch (error) {
      console.error("Failed to update user details", error);
    }
  };

  const addNewOption = (
    query: string,
    setOptions: React.Dispatch<React.SetStateAction<string[]>>,
    setValue: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const newOption = query.trim();
    if (newOption !== "") {
      setOptions((current) => [...current, newOption]);
      setValue((current) => [...current, newOption]);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    setOptions: React.Dispatch<React.SetStateAction<string[]>>,
    setValue: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addNewOption(event.currentTarget.value, setOptions, setValue);
      event.currentTarget.value = ""; // Clear the input field
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={0}>
        <Group px="xl" py="lg" justify="space-between">
          <Title order={4}>Settings</Title>
          <Group justify="space-between" gap="xs">
            <Button className={Classes.darkButton} type="submit">
              Save Settings
            </Button>
          </Group>
        </Group>
        <Divider />

        <Container mt={"xl"} size="62rem">
          <Stack gap="xl">
            <Paper>
              <Stack p="xl" gap="xl">
                <Title order={4}>Subscription</Title>
                <Stack gap="lg">
                  {currentUser.stripeSubscriptionId ? (
                    <CancelSubscriptionButton />
                  ) : (
                    <Stack>
                      You are not subscribed to any plan. Please subscribe to
                      access all features.
                      <SubscribeButton />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Paper>
            <Paper withBorder radius="lg">
              <Stack p="xl" gap="xl">
                <Title order={4}>Account Details</Title>
                <Stack gap="lg">
                  <TextInput
                    size="lg"
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <TextInput
                    size="lg"
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <TextInput
                    size="lg"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <PasswordInput
                    size="lg"
                    label="Password"
                    placeholder="Enter new password (optional)"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    description="Leave this field empty if you don't want to change the password."
                  />
                </Stack>
              </Stack>
            </Paper>
            <Paper withBorder radius="lg">
              <Stack p="xl" gap="xl">
                <Title order={4}>About You</Title>
                <Stack gap="lg">
                  <Textarea
                    size="lg"
                    labelProps={{
                      mb: 0,
                    }}
                    descriptionProps={{
                      mb: 12,
                      lh: 1.6,
                    }}
                    placeholder="Tell us about yourself"
                    description="Introduce yourself, your interests, and what you're looking to achieve."
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    label="Bio"
                    required
                    minRows={8}
                  />
                  <TagsInput
                    data={skillOptions}
                    size="lg"
                    placeholder="Select or enter your skills"
                    description="Select your skills to help match you with the right tasks."
                    labelProps={{
                      mb: 0,
                    }}
                    descriptionProps={{
                      mb: 12,
                      lh: 1.6,
                    }}
                    value={skills}
                    onChange={setSkills}
                    label="Skills"
                    required
                    onKeyDown={(event) =>
                      handleKeyDown(event, setSkillOptions, setSkills)
                    }
                  />
                  <TagsInput
                    data={interestOptions}
                    size="lg"
                    placeholder="Select your interests"
                    description="Select your interests to make your tasks more fun."
                    labelProps={{
                      mb: 0,
                    }}
                    descriptionProps={{
                      mb: 12,
                      lh: 1.6,
                    }}
                    value={interests}
                    onChange={setInterests}
                    label="Interests"
                    required
                    onKeyDown={(event) =>
                      handleKeyDown(event, setInterestOptions, setInterests)
                    }
                  />
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </Container>
      </Stack>
    </form>
  );
};

export default EditUserDetails;
