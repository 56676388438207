import {
  Anchor,
  BackgroundImage,
  Card,
  Center,
  Container,
  Divider,
  Group,
  Image,
  Overlay,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import Login from "../../components/Auths/Login";
import Register from "../../components/Auths/Register";

const RegisterPage = () => {
  return (
    <Center h={"100vh"} pb="20%" bg="gray.0">
      <Stack p="xl" gap={42} maw={420}>
        <Image fit="contain" mah={72} w={"100%"} src="logo.svg" />
        <Stack ta="center" gap={6}>
          <Title>Create a free account</Title>
          <Text size="lg">
            Big projects start with small steps. Create an account to get
            started.
          </Text>
        </Stack>
        <Register />
        <Text ta="center">
          Already have an account?
          <Anchor href="/login" ml={4} underline="never">
            Sign in
          </Anchor>
        </Text>
      </Stack>
    </Center>
  );
};

export default RegisterPage;
