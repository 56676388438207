import {
  Anchor,
  BackgroundImage,
  Card,
  Center,
  Container,
  Divider,
  Group,
  Image,
  Overlay,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import Login from "../../components/Auths/Login";
import SetPassword from "../../components/Auths/SetPassword";

const PasswordPage = () => {
  return (
    <Center h={"100vh"}>
      <Stack align="center">
        <Paper radius={"xl"} withBorder p={"xl"}>
          <Stack gap={"xl"} align="stretch" p={"md"}>
            <Image fit="contain" mah={72} w={"100%"} src="logo.svg" />
            <Stack w={420} ta={"center"}>
              <Title>Create a password</Title>
              <Text size="lg">Please create a password for your account</Text>
            </Stack>

            <SetPassword />
          </Stack>
        </Paper>
      </Stack>
    </Center>
  );
};

export default PasswordPage;
