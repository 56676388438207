import React, { useState, useEffect } from "react";
import { User } from "../../types";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import EditUserDetails from "../../components/Auths/EditDetails";

const UserSettings: React.FC = () => {
  const { currentUser } = useAuth();

  return (
    <EditUserDetails
      onComplete={function (data: {
        skills: string[];
        interests: string[];
        bio: string;
      }): void {
        throw new Error("Function not implemented.");
      }}
      onSkip={function (): void {
        throw new Error("Function not implemented.");
      }}
      {...(currentUser as User)}
    />
  );
};

export default UserSettings;
