// src/axiosConfig.ts
import axios from "axios";

console.log("REACT_APP_LIVE_API:", process.env.REACT_APP_LIVE_API);

const baseURL = process.env.REACT_APP_LIVE_API || "https://api.taskjoy.app";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = baseURL;

console.log("Axios baseURL:", axios.defaults.baseURL);

export default axios;
