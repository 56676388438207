import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PasswordInput, Button, Stack, TextInput } from "@mantine/core";
import { IconKey, IconLock } from "@tabler/icons-react";
import axios from "axios";

const SetPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return alert("Passwords do not match");
    }
    try {
      await axios.post("/api/auth/set-password", { token, password });
      navigate("/login");
    } catch (error) {
      console.error("Failed to set password", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={"lg"}>
        <PasswordInput
          size="lg"
          rightSection={<IconKey size={24} stroke={1} />}
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <PasswordInput
          size="lg"
          rightSection={<IconKey size={24} stroke={1} />}
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button mt={"sm"} size="lg" type="submit">
          Set Password
        </Button>
      </Stack>
    </form>
  );
};

export default SetPassword;
