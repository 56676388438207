// axiosSetup.js
import axios from "axios";

// Function to setup the interceptor
export const setupAxiosInterceptor = (onChangeDetected: () => void) => {
  axios.interceptors.request.use(
    (config) => {
      // Detect if the method is POST, DELETE, or PUT
      if (
        config.method === "post" ||
        config.method === "delete" ||
        config.method === "put" ||
        config.method === "patch"
      ) {
        onChangeDetected();
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
