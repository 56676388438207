import React, { useState, useEffect } from "react";
import {
  ActionIcon,
  Stack,
  Group,
  Modal,
  Button,
  Card,
  Text,
  Grid,
  Paper,
  Center,
  RingProgress,
  Menu,
  Title,
  Box,
  Image,
} from "@mantine/core";
import { IconCheck, IconDotsVertical, IconPlus } from "@tabler/icons-react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Classes from "./Styling/Projects.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { Workspace, Task, User } from "../../types";
import CreateWorkspaceForm from "./Create";
import EditWorkspaceForm from "./Create/edit";

const WorkSpaceGrid: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [opened, { open, close }] = useDisclosure(false);

  const [
    confirmDeleteOpened,
    { open: openConfirmDelete, close: closeConfirmDelete },
  ] = useDisclosure(false);
  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);

  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null
  );
  const [workspaceToDelete, setWorkspaceToDelete] = useState<Workspace | null>(
    null
  );
  const [workspaceToEdit, setWorkspaceToEdit] = useState<Workspace | null>(
    null
  );

  useEffect(() => {
    const fetchWorkspaces = async () => {
      if (!currentUser) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces?teamId=${currentUser._id}`
        );
        const workspacesData = response.data;

        // Fetch projects and tasks for each workspace
        const workspacesWithProjectsAndTasks = await Promise.all(
          workspacesData.map(async (workspace: Workspace) => {
            const projectsResponse = await axios.get(
              `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspace._id}/projects`
            );
            const projectsData = projectsResponse.data;

            const projectsWithTasks = await Promise.all(
              projectsData.map(async (project: { _id: any }) => {
                const tasksResponse = await axios.get(
                  `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspace._id}/projects/${project._id}/tasks`
                );
                return { ...project, tasks: tasksResponse.data };
              })
            );

            return { ...workspace, projects: projectsWithTasks };
          })
        );

        setWorkspaces(workspacesWithProjectsAndTasks);

        if (workspacesWithProjectsAndTasks.length > 0 && !selectedWorkspace) {
          setSelectedWorkspace(workspacesWithProjectsAndTasks[0]);
        }
      } catch (error) {
        console.error("Failed to fetch workspaces", error);
      }
    };

    fetchWorkspaces();
  }, [currentUser, selectedWorkspace]);

  const calculateWorkspaceCompletion = (workspace: Workspace) => {
    if (!workspace.projects || workspace.projects.length === 0) {
      return 0;
    }

    let totalTasks = 0;
    let completedTasks = 0;

    workspace.projects.forEach((project) => {
      if (project.tasks && project.tasks.length > 0) {
        totalTasks += project.tasks.length;
        completedTasks += project.tasks.filter(
          (task: Task) => task.status === "Completed"
        ).length;
      }
    });

    return totalTasks === 0 ? 0 : (completedTasks / totalTasks) * 100;
  };

  const handleDeleteWorkspace = async () => {
    if (!workspaceToDelete) return;
    try {
      await axios.delete(
        `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceToDelete._id}`
      );
      setWorkspaces((prevWorkspaces) =>
        prevWorkspaces.filter((ws) => ws._id !== workspaceToDelete._id)
      );
      setWorkspaceToDelete(null);
      closeConfirmDelete();
    } catch (error) {
      console.error("Failed to delete workspace", error);
    }
  };

  const baseURL = process.env.REACT_APP_LIVE_API;

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        centered
        overlayProps={{
          backgroundOpacity: 0.25,
          blur: 5,
        }}
        size="lg"
        withCloseButton={false}
        radius={"lg"}
        transitionProps={{ transition: "fade", duration: 200 }}>
        <CreateWorkspaceForm />
      </Modal>
      <Modal
        opened={editModalOpened}
        onClose={closeEditModal}
        centered
        size="lg"
        withCloseButton={false}
        radius={"lg"}
        transitionProps={{ transition: "fade", duration: 200 }}>
        {workspaceToEdit && (
          <EditWorkspaceForm
            workspace={workspaceToEdit}
            onUpdate={(updatedWorkspace) => {
              // Update the workspace in the state
              setWorkspaces((prevWorkspaces) =>
                prevWorkspaces.map((ws) =>
                  ws._id === updatedWorkspace._id ? updatedWorkspace : ws
                )
              );
              setWorkspaceToEdit(null);
              closeEditModal();
            }}
          />
        )}
      </Modal>

      <Modal
        opened={confirmDeleteOpened}
        onClose={closeConfirmDelete}
        title="Delete Workspace">
        <Stack>
          <Title order={4}>
            Are you sure you want to delete this workspace?
          </Title>
          <Button onClick={handleDeleteWorkspace} color="red">
            Delete Workspace
          </Button>
        </Stack>
      </Modal>

      <Grid>
        {workspaces.map((workspace) => (
          <Grid.Col
            mah={270}
            mih={270}
            key={workspace._id}
            span={{ xl: 3, lg: 4, md: 6, sm: 6, xs: 12 }}>
            <Card
              mah={290}
              withBorder
              className={Classes.card}
              shadow="md"
              p={1}
              radius="lg">
              <Box
                style={{
                  zIndex: 100,
                }}
                pos={"absolute"}
                top={8}
                right={8}>
                <Menu zIndex={100} position="bottom-end">
                  <Menu.Target>
                    <ActionIcon color="dark" variant="subtle">
                      <IconDotsVertical size={20} stroke={1} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => navigate(`/${workspace._id}/projects`)}>
                      View
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        setWorkspaceToEdit(workspace); // Set the workspace to edit
                        openEditModal(); // Open the edit modal
                      }}>
                      Edit
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        setWorkspaceToDelete(workspace);
                        openConfirmDelete();
                      }}>
                      Delete
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Box>

              <Paper
                radius="lg"
                onClick={() => navigate(`/${workspace._id}/projects`)}
                shadow="none"
                bg={"gray.1"}
                withBorder
                w={"100%"}
                h={160}>
                {!workspace.coverImage ? (
                  <Center h={"100%"} c={"teal.1"}></Center>
                ) : (
                  <Image
                    height={180}
                    fit="cover"
                    style={{
                      zIndex: 0,
                    }}
                    pos="absolute"
                    src={`${baseURL}/${workspace.coverImage}`}
                    radius="sm"
                  />
                )}
              </Paper>
              <Card mt={1} py={"sm"} px={"sm"}>
                <Group wrap="nowrap" justify="space-between">
                  <Stack gap={0}>
                    <Text size="md" fw={600}>
                      {workspace.title}
                    </Text>
                    <Text size="sm" fw={400} truncate maw={"100%"}>
                      Created by{" "}
                      {currentUser._id === workspace.createdBy
                        ? "You"
                        : workspace.createdBy}
                    </Text>
                  </Stack>
                  <RingProgress
                    size={60}
                    thickness={4}
                    sections={[
                      {
                        value: parseFloat(
                          calculateWorkspaceCompletion(workspace).toFixed(2)
                        ),
                        color:
                          calculateWorkspaceCompletion(workspace) === 100
                            ? "teal"
                            : "blue",
                      },
                    ]}
                    label={
                      <Center h={"100%"} w={"100%"}>
                        {calculateWorkspaceCompletion(workspace) === 100 ? (
                          <IconCheck stroke={2} size={32} />
                        ) : (
                          <Center w="100%">
                            <Text size="xs">
                              {`${calculateWorkspaceCompletion(
                                workspace
                              ).toFixed(0)}%`}
                            </Text>
                          </Center>
                        )}
                      </Center>
                    }
                  />
                </Group>
              </Card>
            </Card>
          </Grid.Col>
        ))}
        <Grid.Col span={{ xl: 3, lg: 4, md: 6, sm: 6, xs: 12 }}>
          <Card withBorder h={"100%"}>
            <ActionIcon
              color="gray"
              variant="subtle"
              onClick={open}
              w={"100%"}
              h={"100%"}
              p={0}
              radius="md">
              <Stack h={"100%"} p={"xl"} align="center" justify="center">
                <IconPlus stroke={1} size={32} />
                <Text size="md" fw={500}>
                  Create Workspace
                </Text>
              </Stack>
            </ActionIcon>
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default WorkSpaceGrid;
