import React, { useState, useEffect, useCallback } from "react";
import {
  ActionIcon,
  Stack,
  NavLink,
  Text,
  AppShell,
  Group,
} from "@mantine/core";
import { IconSettings, IconLogout, IconFolder } from "@tabler/icons-react";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import classes from "./index.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { Workspace } from "../../types"; // Adjust the path as necessary
import { setupAxiosInterceptor } from "../../utils/axiosSetup"; // Adjust the path as necessary

interface NavbarProps {
  navColor?: any;
  colorIcon?: any;
}

const Navbar: React.FC<NavbarProps> = ({ navColor, colorIcon }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, currentUser } = useAuth();
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [refreshNavbar, setRefreshNavbar] = useState(false);

  // Function to fetch workspaces
  const fetchWorkspaces = useCallback(async () => {
    if (!currentUser) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LIVE_API}/api/workspaces?teamId=${currentUser._id}`
      );
      setWorkspaces(response.data);
    } catch (error) {
      console.error("Failed to fetch workspaces", error);
    }
  }, [currentUser]);

  useEffect(() => {
    // Initial fetch
    fetchWorkspaces();

    // Setup Axios interceptor to detect changes
    setupAxiosInterceptor(() => setRefreshNavbar((prev) => !prev));

    // Cleanup function
    return () => {
      // Remove interceptor or any other cleanup needed
    };
  }, [fetchWorkspaces, refreshNavbar]);

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_LIVE_API}/api/auth/logout`);
      logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  return (
    <AppShell.Navbar withBorder={false}>
      <Stack
        p={"sm"}
        bg={navColor}
        justify="space-between"
        h="100%"
        align="space-between">
        <AppShell.Section>
          <Stack>
            <Stack gap={2}>
              <NavLink
                defaultOpened
                className={classes.NavItem}
                leftSection={<IconFolder size={20} stroke={1} />}
                label="All Workspaces"
                component={Link}
                to={`/`}
                active={location.pathname.includes("/select-workspace")}
              />
              {workspaces.map((workspace) => (
                <NavLink
                  key={workspace._id}
                  defaultOpened
                  className={classes.NavItem}
                  label={workspace.title}
                  component={Link}
                  to={`/${workspace._id}/projects`}
                  active={location.pathname.includes(
                    `/${workspace._id}/projects`
                  )}
                />
              ))}
            </Stack>
            <Stack gap={4}>
              <Text px="sm" size="sm" mb="sm" c={"dimmed"}>
                Settings
              </Text>
              <NavLink
                defaultOpened
                className={classes.NavItem}
                leftSection={<IconSettings size={20} stroke={1} />}
                label="Account Settings"
                component={Link}
                to={`/settings/account`}
                active={location.pathname.includes("/settings/account")}
              />
            </Stack>
          </Stack>
        </AppShell.Section>
        <AppShell.Section>
          <Group p="sm" gap={8}>
            <ActionIcon size="lg" radius="md" variant="default">
              <IconSettings stroke={1} size={24} />
            </ActionIcon>
            {colorIcon}
            <ActionIcon
              onClick={handleLogout}
              size="lg"
              radius="md"
              variant="default">
              <IconLogout stroke={1} size={24} />
            </ActionIcon>
          </Group>
        </AppShell.Section>
      </Stack>
    </AppShell.Navbar>
  );
};

export default Navbar;
