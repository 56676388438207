import React, { useState, useEffect } from "react";
import {
  Stack,
  Group,
  Card,
  Text,
  Divider,
  Title,
  ActionIcon,
  Modal,
  Anchor,
  Breadcrumbs,
} from "@mantine/core";
import { IconFolder, IconPlus } from "@tabler/icons-react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "@mantine/spotlight/styles.css";
import { useAuth } from "../contexts/AuthContext";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { Workspace } from "../types";
import WorkSpaceGrid from "../components/Workspaces";
import CreateWorkspaceForm from "../components/Workspaces/Create";

const SelectWorkspace: React.FC = ({}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [opened, { open, close }] = useDisclosure(false);
  const [
    projectModalOpened,
    { open: openProjectModal, close: closeProjectModal },
  ] = useDisclosure(false);
  const [
    confirmDeleteOpened,
    { open: openConfirmDelete, close: closeConfirmDelete },
  ] = useDisclosure(false);

  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null
  );
  const [workspaceToDelete, setWorkspaceToDelete] = useState<Workspace | null>(
    null
  );

  useEffect(() => {
    const fetchWorkspaces = async () => {
      if (!currentUser) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces?teamId=${currentUser._id}`
        );
        console.log("Fetched workspaces:", response.data);
        setWorkspaces(response.data);
        if (response.data.length > 0 && !selectedWorkspace) {
          setSelectedWorkspace(response.data[0]);
        }
      } catch (error) {
        console.error("Failed to fetch workspaces", error);
      }
    };

    fetchWorkspaces();
  }, [currentUser, selectedWorkspace]);

  const handleWorkspaceCreate = (newWorkspace: Workspace) => {
    setWorkspaces((prevWorkspaces) => [...prevWorkspaces, newWorkspace]);
    setSelectedWorkspace(newWorkspace);
    navigate(`/workspaces/${newWorkspace._id}/projects`);
  };

  const handleWorkspaceChange = (workspaceId: string | null) => {
    console.log("Selected Workspace ID:", workspaceId);
    const workspace = workspaces.find((ws) => ws._id === workspaceId);
    if (workspace) {
      console.log("Workspace found:", workspace);
      setSelectedWorkspace(workspace);
    }
  };

  const handleDeleteWorkspace = async () => {
    if (!workspaceToDelete) return;
    try {
      await axios.delete(
        `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceToDelete._id}`
      );
      setWorkspaces((prevWorkspaces) =>
        prevWorkspaces.filter((ws) => ws._id !== workspaceToDelete._id)
      );
      setWorkspaceToDelete(null);
      closeConfirmDelete();
    } catch (error) {
      console.error("Failed to delete workspace", error);
    }
  };

  const projectForm = useForm({
    initialValues: {
      title: "",
      description: "",
    },
  });

  const handleProjectSubmit = async (values: {
    title: string;
    description: string;
  }) => {
    if (!selectedWorkspace) return;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LIVE_API}/api/workspaces/${selectedWorkspace._id}/projects`,
        {
          ...values,
        }
      );
      setWorkspaces((prevWorkspaces) =>
        prevWorkspaces.map((ws) =>
          ws._id === selectedWorkspace._id
            ? { ...ws, projects: [...ws.projects, response.data] }
            : ws
        )
      );
      closeProjectModal();
      projectForm.reset();
    } catch (error) {
      console.error("Failed to create project", error);
    }
  };

  const items = [{ title: "All", href: "/" }].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        centered
        overlayProps={{
          backgroundOpacity: 0.25,
          blur: 5,
        }}
        size="lg"
        withCloseButton={false}
        radius={"lg"}
        transitionProps={{ transition: "fade", duration: 200 }}>
        <CreateWorkspaceForm />
      </Modal>

      <Group px="xl" py="lg" justify="space-between">
        <Title order={4}>All workspaces</Title>
        <ActionIcon onClick={open} size="md" title="Create new workspace">
          <IconPlus size={20} stroke={1} />
        </ActionIcon>
      </Group>
      <Divider />

      <Stack gap={0}>
        <Stack p={"xl"} gap={"xl"}>
          <Breadcrumbs>{items}</Breadcrumbs>

          <WorkSpaceGrid />
        </Stack>
      </Stack>
    </>
  );
};

export default SelectWorkspace;
