import axios from "axios";
import {
  ActionIcon,
  Card,
  Group,
  Menu,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Task } from "../../../types";
import { useState } from "react";

export interface ProjectProps {
  project: {
    _id?: any;
    title?: string;
    assignees?: string[];
    date?: string;
    dueDate?: string;
    createdAt: any;
    tasks?: Task[];
    createdBy?: string;
    status?: string;
  };
  onEditClick: (project: any) => void;
  onDelete: (projectId: any) => void; // This function will handle deletion in the parent
}

const ProjectCard = ({ project, onEditClick, onDelete }: ProjectProps) => {
  const { workspaceId, projectId } = useParams();

  // Handle delete
  const handleDelete = async (taskId: string) => {
    try {
      // Set task status to 'archived' before deletion
      await axios.put(
        `/api/workspaces/${workspaceId}/projects/${projectId}/tasks/${taskId}`,
        { status: "archived" }
      );

      // Proceed to delete the task
      await axios.delete(
        `/api/workspaces/${workspaceId}/projects/${projectId}/tasks/${taskId}`
      );

      onDelete(taskId); // Notify the parent to update the list
    } catch (error) {
      console.error("Failed to delete task", error);
    }
  };
  return (
    <Card miw={220} withBorder p={0} radius={"md"} shadow="sm">
      <Stack pb={2} align="left">
        <Card p={"md"}>
          <Group justify="space-between" wrap="nowrap">
            <Group wrap="nowrap" onClick={() => onEditClick(project)}>
              <Paper
                h={40}
                w={4}
                radius={100}
                bg={
                  project.status === "To Do"
                    ? "gray.6"
                    : project.status === "In Progress"
                    ? "orange.6"
                    : project.status === "In Review"
                    ? "violet.6"
                    : project.status === "Completed"
                    ? "teal.6"
                    : project.status === "Changes Requested"
                    ? "orange.6"
                    : "red.6"
                }
              />
              <Stack gap={4}>
                <Title fw={400} order={6}>
                  {project.title}
                </Title>
                <Text size="xs" c={"dimmed"}>
                  Due {dayjs(project.dueDate).format("DD MMM, YYYY")}
                </Text>
              </Stack>
            </Group>
            <Menu width={120}>
              <Menu.Target>
                <Group>
                  <ActionIcon bg={"none"} c={"dimmed"} size={18} variant="none">
                    <IconDotsVertical />
                  </ActionIcon>
                </Group>
              </Menu.Target>
              <Menu.Dropdown>
                <Stack gap="xs">
                  <Menu.Item onClick={() => onEditClick(project)}>
                    Edit
                  </Menu.Item>
                  <Menu.Item onClick={() => handleDelete(project._id)}>
                    Deletess
                  </Menu.Item>
                </Stack>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Card>
      </Stack>
    </Card>
  );
};

export default ProjectCard;
