import React from "react";
import ReactDOM from "react-dom/client";
import "@mantine/tiptap/styles.css";
import "@mantine/core/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthContext"; // Import AuthProvider
import { MantineProvider } from "@mantine/core";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <MantineProvider forceColorScheme="dark">
    <AuthProvider>
      <App />
    </AuthProvider>
  </MantineProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
