import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { TextInput, Button, Stack, PasswordInput, Alert } from "@mantine/core";
import { IconKey, IconMail, IconAlertCircle } from "@tabler/icons-react";
import Classes from "./index.module.scss";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to handle button disabling
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Clear any previous error

    // Basic validation
    if (!email || !password) {
      setError("Please fill in both email and password.");
      return;
    }

    setIsSubmitting(true); // Disable the button to prevent multiple submissions

    try {
      await login(email, password);
      navigate("/");
    } catch (error: any) {
      console.error("Failed to log in", error);

      // Check for different error scenarios and provide contextual feedback
      const errorMessages: { [key: number]: string } = {
        400: "Invalid email or password. Please check your credentials.",
        401: "Unauthorized access. Please ensure your account is active.",
        403: "Your account is locked. Please contact support.",
        500: "Server error. Please try again later.",
      };

      if (error.response) {
        // Errors from server
        setError(
          errorMessages[error.response.status] ||
            "Failed to log in. Please try again."
        );
      } else if (error.request) {
        // Errors related to the network
        setError("Network error. Please check your internet connection.");
      } else {
        // Other errors
        setError("An unexpected error occurred. Please try again.");
      }

      // Optionally clear password field on error
      setPassword("");
    } finally {
      setIsSubmitting(false); // Re-enable the button after handling the error
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap="md">
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} />}
            title="Login Error"
            color="red">
            {error}
          </Alert>
        )}
        <TextInput
          size="lg"
          withAsterisk
          radius="md"
          rightSection={<IconMail size={24} stroke={1} />}
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isSubmitting} // Disable input during submission
        />
        <PasswordInput
          size="lg"
          radius="md"
          withAsterisk
          rightSection={<IconKey size={24} stroke={1} />}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isSubmitting} // Disable input during submission
        />
        <Button
          className={Classes.darkButton}
          radius="md"
          type="submit"
          disabled={isSubmitting} // Disable button during submission
        >
          {isSubmitting ? "Signing In..." : "Sign In"}
        </Button>
      </Stack>
    </form>
  );
};

export default Login;
