import { Card, Group, Stack, Text, Title } from "@mantine/core";
import { IconFolder } from "@tabler/icons-react";
import CreateCard from "../../../components/Dashboard/CreateCard";
import { User } from "../../../types";
import { useAuth } from "../../../contexts/AuthContext";
import { useState } from "react";

const ClientSettings = () => {
  const currentUser = useAuth();
  const [user, setUser] = useState<User | null>(null);

  return (
    <Card p={"xl"} shadow="none">
      <Group justify="space-between">
        <Stack gap={8} justify="center">
          <Title fw={500} order={4}>
            👋 Welcome
          </Title>
          <Text size="sm" c="dimmed">
            Here's what's happening with your account
          </Text>
        </Stack>

        <CreateCard
          heading="Create a new workspace"
          description="Organize your projects in a workspace"
          cardIcon={<IconFolder size={24} />}
        />
      </Group>
    </Card>
  );
};

export default ClientSettings;
