import React, { useState } from "react";
import { useForm } from "@mantine/form";
import {
  Stack,
  TextInput,
  Textarea,
  Button,
  Modal,
  Switch,
  Title,
  Text,
  SegmentedControl,
  Input,
  ActionIcon,
  Loader,
  Overlay,
  MultiSelect,
  Group,
  Container,
} from "@mantine/core";
import axios from "axios";
import { useDisclosure } from "@mantine/hooks";
import { useAuth } from "../../../contexts/AuthContext";
import { IconWand, IconX } from "@tabler/icons-react";

interface GenerateProjectProps {
  selectedWorkspaceId: string;
  onProjectCreated: (project: any) => void;
}

const GenerateProject: React.FC<GenerateProjectProps> = ({
  selectedWorkspaceId,
  onProjectCreated,
}) => {
  const { currentUser } = useAuth();
  const [
    projectModalOpened,
    { open: openProjectModal, close: closeProjectModal },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  const [projectType, setProjectType] = useState("Project");

  const projectForm = useForm({
    initialValues: {
      title: "",
      description: "",
      includeMilestones: true,
      detailedTasks: true,
      assignToCurrentUser: true,
      projectType: "",
      experienceLevel: "Beginner",
      preferredResources: "",
      projectGoals: "",
      potentialChallenges: "",
      additionalNotes: "",
      actionsTaken: "",
    },
  });

  const handleProjectSubmit = async (values: {
    title: string;
    description: string;
    includeMilestones: boolean;
    detailedTasks: boolean;
    assignToCurrentUser: boolean;
    projectType: string;
    experienceLevel: string;
    preferredResources: string;
    projectGoals: string;
    potentialChallenges: string;
    additionalNotes: string;
    actionsTaken: string; // Include actionsTaken here
  }) => {
    if (!selectedWorkspaceId) return;
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LIVE_API}/api/workspaces/${selectedWorkspaceId}/projects/generate`,
        {
          ...values,
          projectType,
          userId: currentUser._id,
          userSkills: currentUser.skills || [],
          userInterests: currentUser.interests || [],
          userBio: currentUser.bio || "",
          actionsTaken: values.actionsTaken, // Pass actionsTaken to the backend
        }
      );
      onProjectCreated(response.data);
      closeProjectModal();
      window.location.reload();
      projectForm.reset();
    } catch (error) {
      console.error("Failed to create project", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ActionIcon
        c={"dimmed"}
        onClick={openProjectModal}
        w={"100%"}
        variant="default"
        h={"100%"}
        p={0}
        radius="md">
        <Stack h={"100%"} p={"xl"} align="center" justify="center">
          <IconWand stroke={1} size={32} />
          <Text size="md" fw={500}>
            Generate Project
          </Text>
        </Stack>
      </ActionIcon>
      <Modal
        size="xl"
        overlayProps={{
          blur: 10,
        }}
        radius="lg"
        withCloseButton={false}
        opened={projectModalOpened}
        onClose={closeProjectModal}>
        <form onSubmit={projectForm.onSubmit(handleProjectSubmit)}>
          <Stack p="xl" gap="xl">
            <Group justify="space-between" wrap="nowrap">
              <Stack gap={4}>
                <Title fw={550} order={2}>
                  Generate a new {projectType.toLowerCase()}
                </Title>
                <Text>
                  Let Joy break down your project or goal into actionable tasks.
                </Text>
              </Stack>
              <ActionIcon
                onClick={closeProjectModal}
                radius="xl"
                variant="light"
                size="xl">
                <IconX size={24} />
              </ActionIcon>
            </Group>
            <Stack gap="xl">
              {/* <Input.Wrapper
                  w="100%"
                  mb="sm"
                  label="What type of project would you like to generate?"
                  labelProps={{
                    mb: 0,
                  }}>
                  <SegmentedControl
                    fullWidth
                    radius="xl"
                    size="md"
                    color="teal"
                    value={projectType}
                    onChange={(value) => {
                      setProjectType(value);
                      projectForm.setFieldValue("projectType", value);
                    }}
                    data={["Goal", "Project", "Lesson"]}
                  />
                </Input.Wrapper> */}
              <TextInput
                description={`Give your ${projectType.toLowerCase()} a title`}
                labelProps={{
                  mb: 0,
                }}
                descriptionProps={{
                  mb: 12,
                  lh: 1.6,
                }}
                size="lg"
                required
                label={`${projectType} Title`}
                placeholder={
                  projectType === "Lesson"
                    ? 'e.g. "Cooking Class"'
                    : 'e.g. "Community Event Planning"'
                }
                {...projectForm.getInputProps("title")}
              />
              <Textarea
                size="lg"
                labelProps={{
                  mb: 0,
                }}
                descriptionProps={{
                  mb: 12,
                  lh: 1.6,
                }}
                required
                minRows={8}
                rows={8}
                label={`Describe your ${projectType.toLowerCase()}`}
                description="Tell us more about what you want to achieve..."
                placeholder='e.g. "I want to plan a community event to promote local businesses."'
                {...projectForm.getInputProps("description")}
              />

              <TextInput
                label="Preferred Resources"
                description="Specify any preferred resources (e.g., tools, locations, materials)"
                placeholder="e.g., Social media tools, local venues, digital marketing software"
                labelProps={{
                  mb: 0,
                }}
                descriptionProps={{
                  mb: 12,
                  lh: 1.6,
                }}
                {...projectForm.getInputProps("preferredResources")}
              />
              <Textarea
                size="lg"
                label="Project Goals"
                description="What do you want to accomplish with this project?"
                placeholder='e.g., "Increase community engagement and promote local businesses."'
                labelProps={{
                  mb: 0,
                }}
                descriptionProps={{
                  mb: 12,
                  lh: 1.6,
                }}
                {...projectForm.getInputProps("projectGoals")}
              />
              <Textarea
                size="lg"
                label="Potential Challenges"
                description="Are there any challenges you foresee in this project?"
                placeholder='e.g., "Finding sponsors and volunteers for the event."'
                labelProps={{
                  mb: 0,
                }}
                descriptionProps={{
                  mb: 12,
                  lh: 1.6,
                }}
                {...projectForm.getInputProps("potentialChallenges")}
              />
              <Textarea
                size="lg"
                label="Things Done So Far"
                description="Outline any actions you've already taken towards this project."
                placeholder='e.g., "Created a social media page and contacted local vendors."'
                labelProps={{
                  mb: 0,
                }}
                descriptionProps={{
                  mb: 12,
                  lh: 1.6,
                }}
                {...projectForm.getInputProps("actionsTaken")}
              />
              <Textarea
                size="lg"
                label="Additional Notes"
                description="Any other information or instructions?"
                placeholder="e.g., 'Include social media promotion strategies.'"
                labelProps={{
                  mb: 0,
                }}
                descriptionProps={{
                  mb: 12,
                  lh: 1.6,
                }}
                {...projectForm.getInputProps("additionalNotes")}
              />
            </Stack>
            <Switch
              label="Include Milestones"
              {...projectForm.getInputProps("includeMilestones", {
                type: "checkbox",
              })}
            />
            <Switch
              label="Generate Detailed Tasks"
              {...projectForm.getInputProps("detailedTasks", {
                type: "checkbox",
              })}
            />
            <Switch
              label="Assign Tasks to Current User"
              {...projectForm.getInputProps("assignToCurrentUser", {
                type: "checkbox",
              })}
            />
            <Button type="submit" disabled={loading}>
              Generate {projectType}
            </Button>
          </Stack>
        </form>
        {loading && (
          <Overlay center opacity={0.6} color="#000" zIndex={1000}>
            <Loader size="xl" color="white" variant="dots" />
          </Overlay>
        )}
      </Modal>
    </>
  );
};

export default GenerateProject;
