import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ActionIcon,
  Avatar,
  Center,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import TopSection from "../../components/TopSection";
import { useDisclosure } from "@mantine/hooks";

import { useAuth } from "../../contexts/AuthContext";
import { IconBell } from "@tabler/icons-react";

const UserProfile = () => {
  const { currentUser, login, logout } = useAuth();

  return (
    <Stack gap={"xl"}>
      <Grid>
        <Grid.Col span={4}>
          <Paper withBorder radius={"lg"} p={"lg"}>
            <Group align="center">
              <Avatar>{currentUser.first_name[0]}</Avatar>
              <Stack gap={0}>
                <Title order={3}>
                  {currentUser.first_name} {currentUser.last_name}
                </Title>
                <Text>{currentUser?.email}</Text>
              </Stack>
            </Group>
          </Paper>
        </Grid.Col>
        <Grid.Col span={8}>
          <Paper withBorder radius={"lg"} p={"lg"}>
            <Stack>
              <Group gap={8} align="center">
                <ActionIcon size={"lg"} radius={"md"} variant="default">
                  <IconBell stroke={1} size={24} />
                </ActionIcon>

                <Title order={3}>Notifications</Title>
              </Group>
              <Paper withBorder radius={"md"} p={"lg"}>
                <Text>
                  You have no notifications at the moment. Check back later.
                </Text>
              </Paper>
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};

export default UserProfile;
