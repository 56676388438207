import { Card, Grid, Overlay, Paper, Stack, Text, Title } from "@mantine/core";
import { IconFolder } from "@tabler/icons-react";
import Classes from "./index.module.scss";

interface CreateCardProps {
  heading: string;
  description: string;
  cardIcon: React.ReactNode;
  commingSoon?: boolean;
}

const CreateCard = ({
  heading,
  description,
  cardIcon,
  commingSoon,
}: CreateCardProps) => {
  return (
    <Card
      opacity={commingSoon ? 0.25 : 1}
      className={commingSoon ? "" : Classes.card}
      radius={"md"}
      withBorder
      shadow="none"
      ta="left">
      <Grid gutter="lg" align="center">
        <Grid.Col span={"content"}>{cardIcon}</Grid.Col>
        <Grid.Col span={"auto"}>
          <Stack gap={4}>
            <Title order={5} fw={500}>
              {heading}
            </Title>
            <Text size="sm" c="dimmed">
              {description}
            </Text>
          </Stack>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default CreateCard;
