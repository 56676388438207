import React, { useState, useEffect, useRef } from "react";
import {
  TextInput,
  MultiSelect,
  Button,
  Grid,
  Select,
  Paper,
  Textarea,
  Kbd,
  ScrollArea,
  Stack,
  Text,
  UnstyledButton,
  Group,
  Card,
  Divider,
  Menu,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import TaskItem from "@tiptap/extension-task-item";
import TipTapTaskList from "@tiptap/extension-task-list";
import { RichTextEditor, Link, getTaskListExtension } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import axios from "axios";
import { useDebouncedCallback } from "@mantine/hooks";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  IconCalendar,
  IconChevronDown,
  IconClock,
  IconEye,
  IconFlag,
  IconFlagFilled,
  IconPlayerPause,
  IconPlayerPlay,
  IconStatusChange,
  IconUserCircle,
  IconUsers,
} from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import { Task } from "../../../../types";
import { useParams } from "react-router-dom";
import classes from "./index.module.scss";

const CreateTask: React.FC<{
  task?: any;
  onTaskCreate: (task: any) => void;
  onTaskEdit: (task: any) => void;
}> = ({ task, onTaskCreate, onTaskEdit }) => {
  const { currentUser } = useAuth();
  const { workspaceId, projectId } = useParams();
  const [team, setTeam] = useState<{ value: string; label: string }[]>([]);
  const [comments, setComments] = useState<any[]>(task ? task.comments : []);
  const [currentTask, setCurrentTask] = useState<Task | null>(null);

  useEffect(() => {
    const fetchTeam = async () => {
      if (!currentUser) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_API}/api/team?childOf=${currentUser._id}`
        );
        const teamMembers = response.data.map((member: any) => ({
          value: member._id,
          label: `${member.first_name} ${member.last_name}`,
        }));
        setTeam([
          {
            value: currentUser._id,
            label: `${currentUser.first_name} ${currentUser.last_name}`,
          },
          ...teamMembers,
        ]);
      } catch (error) {
        console.error("Failed to fetch team", error);
      }
    };

    fetchTeam();
  }, [currentUser]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      getTaskListExtension(TipTapTaskList),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: "test-item",
        },
      }),
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: task ? task.content : "",
    onUpdate: () => {
      handleChange();
    },
  });

  const form = useForm({
    initialValues: {
      title: task ? task.title : "",
      assignees: task ? task.assignees : [],
      time: task ? task.time : "",
      user: task ? task.user : "",
      status: task ? task.status : "",
      comment: "",
      content: task ? task.content : "",
      dueDate: task ? new Date(task.dueDate) : new Date(),
    },
  });

  useEffect(() => {
    if (task) {
      form.setValues({
        comment: "",
        title: task.title,
        time: task.time,
        user: task.user,
        assignees: task.assignees,
        content: task.content,
        status: task.status,
        dueDate: new Date(task.dueDate),
      });
      editor?.commands.setContent(task.content);
      setComments(task.comments || []);
    }
  }, [task]);

  const debouncedSubmit = useDebouncedCallback(async (values) => {
    try {
      const taskData = {
        title: values.title,
        assignees: values.assignees,
        status: values.status,
        content: editor?.getHTML() || "",
        dueDate: values.dueDate.toISOString(),
      };
      if (task) {
        const response = await axios.put(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceId}/projects/${projectId}/tasks/${task._id}`,
          taskData
        );
        console.log("Task updated successfully:", response.data);
        onTaskEdit(response.data);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceId}/projects/${projectId}/tasks`,
          taskData
        );
        console.log("Task created successfully:", response.data);
        onTaskCreate(response.data);
      }
    } catch (error) {
      console.error("Failed to submit task:", error);
    }
  }, 500);

  const handleChange = () => {
    const updatedValues = {
      ...form.values,
      title: form.values.title || "",
      assignees: form.values.assignees || "",
      status: form.values.status || "",
      dueDate: form.values.dueDate || "",
      content: editor?.getHTML() || "",
    };
    debouncedSubmit(updatedValues);
  };

  useEffect(() => {
    handleChange();
  }, [form.values, editor]);

  const handleSubmit = async (values: typeof form.values) => {
    try {
      const taskData = {
        title: values.title || "",
        assignees: values.assignees || "",
        status: values.status || "",
        content: editor?.getHTML() || "",
        dueDate: values.dueDate.toISOString(), // Format dueDate as ISO string
      };
      if (task) {
        const response = await axios.put(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceId}/projects/${projectId}/tasks/${task._id}`,
          taskData
        );
        console.log("Task updated successfully:", response.data);
        onTaskEdit(response.data);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceId}/projects/${projectId}/tasks`,
          taskData
        );
        console.log("Task created successfully:", response.data);
        onTaskCreate(response.data);
      }
    } catch (error) {
      console.error("Failed to submit task:", error);
    }
  };

  const handleSubmitComment = async (values: typeof form.values) => {
    try {
      const commentData = {
        user: currentUser._id,
        content: values.comment,
        createdAt: new Date(),
      };
      if (task) {
        const response = await axios.post(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceId}/projects/${projectId}/tasks/${task._id}/comments`,
          commentData
        );
        console.log("Comment added successfully:", response.data);

        // Update the comments state with the new comment
        const newComment = {
          ...commentData,
          _id: response.data._id, // Make sure to use the ID returned from the response
          user: {
            _id: currentUser._id,
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
          },
        };

        setComments((prevComments) => [...prevComments, newComment]);
        form.setFieldValue("comment", ""); // Reset the comment input
      }
    } catch (error) {
      console.error("Failed to submit comment:", error);
    }
  };

  useEffect(() => {
    startAtBottom();
  }, [comments]);

  const viewport = useRef<HTMLDivElement>(null);
  const scrollToBottom = () =>
    viewport.current!.scrollTo({
      top: viewport.current!.scrollHeight,
      behavior: "smooth",
    });

  const startAtBottom = () =>
    viewport.current!.scrollTo({
      top: viewport.current!.scrollHeight,
      behavior: "auto",
    });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Group justify="space-between">
          <Button.Group>
            <Menu position="bottom-start">
              <Menu.Target>
                <Button
                  variant="default"
                  px={12}
                  rightSection={<IconChevronDown stroke={1.25} size={16} />}
                  w={"fit-content"}>
                  <IconFlag size={20} stroke={1.5} />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item>Low Priority</Menu.Item>
                <Menu.Item>Normal Priority</Menu.Item>
                <Menu.Item>High Priority</Menu.Item>
                <Menu.Item>Urgent Priority</Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Button
              variant="default"
              px={12}
              rightSection={<IconChevronDown stroke={1.25} size={16} />}
              w={"fit-content"}>
              <IconUsers size={20} stroke={1.5} />
            </Button>
            <Button
              variant="default"
              px={12}
              rightSection={<IconChevronDown stroke={1.25} size={16} />}
              w={"fit-content"}>
              <IconFlagFilled size={20} stroke={1.5} />
            </Button>
            <Menu position="bottom-start">
              <Menu.Target>
                <Button
                  variant="default"
                  px={12}
                  rightSection={<IconChevronDown stroke={1.25} size={16} />}
                  w={"fit-content"}>
                  <IconStatusChange size={20} stroke={1.5} />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => {
                    form.setFieldValue("status", "To Do");
                  }}>
                  To Do
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    form.setFieldValue("status", "In Progress");
                  }}>
                  In Progress
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    form.setFieldValue("status", "In Review");
                  }}>
                  In Review
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    form.setFieldValue("status", "Changes Requested");
                  }}>
                  Changes Requested
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    form.setFieldValue("status", "Completed");
                  }}>
                  Complete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>

            <DatePickerInput
              size="sm"
              radius={0}
              leftSection={<IconCalendar size={20} stroke={1} />}
              placeholder="Due Date"
              value={form.values.dueDate}
              onChange={(date) =>
                form.setFieldValue("dueDate", date || new Date())
              }
            />
            <Button
              variant="default"
              px={12}
              rightSection={<IconChevronDown stroke={1.25} size={16} />}
              w={"fit-content"}>
              <IconEye size={20} stroke={1.5} />
            </Button>
          </Button.Group>

          <Button.Group>
            <Button
              variant="default"
              px={12}
              w={"fit-content"}
              leftSection={<IconClock size={20} stroke={1.5} />}>
              00:00:00
            </Button>
            <Button variant="default" px={12} w={"fit-content"}>
              <IconPlayerPlay size={20} stroke={1.5} />
            </Button>
            <Button variant="default" px={12} w={"fit-content"}>
              <IconPlayerPause size={20} stroke={1.5} />
            </Button>
          </Button.Group>
        </Group>

        <Grid align="center">
          <Grid.Col span={7}>
            <TextInput
              w={"100%"}
              size="sm"
              placeholder="Project Title"
              {...form.getInputProps("title")}
              withAsterisk
            />
          </Grid.Col>

          <Grid.Col span={"auto"}>
            <MultiSelect
              size="sm"
              leftSection={<IconUserCircle size={20} stroke={1} />}
              data={team}
              {...form.getInputProps("assignees")}
            />
          </Grid.Col>

          <Grid.Col span={"auto"}>
            <Select
              size="sm"
              data={[
                { value: "To Do", label: "To Do" },
                { value: "In Progress", label: "In Progress" },
                { value: "In Review", label: "In Review" },
                {
                  value: "Changes Requested",
                  label: "Changes Requested",
                },
                { value: "Complete", label: "Complete" },
              ]}
              placeholder="Select status"
              {...form.getInputProps("status")}
            />
          </Grid.Col>
        </Grid>
        <Grid gutter="md">
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={7}>
                <RichTextEditor classNames={classes} mih={800} editor={editor}>
                  <RichTextEditor.Toolbar sticky stickyOffset={0}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.TaskList />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>
                  <ScrollArea h={800}>
                    <RichTextEditor.Content mih={800} />
                  </ScrollArea>
                </RichTextEditor>
              </Grid.Col>

              <Grid.Col span={5}>
                <Paper
                  p={"md"}
                  bg={"#ffffff05"}
                  withBorder
                  w={"100%"}
                  h={600}
                  radius={"md"}>
                  <Stack h={"100%"} justify="end" align="stretch">
                    <ScrollArea mah={500} viewportRef={viewport}>
                      {comments?.length > 0 ? (
                        comments.map((comment: any) => (
                          <Stack align="stretch" w={"100%"}>
                            <Group
                              justify={currentUser ? "flex-end" : "flex-start"}
                              style={{ width: "100%" }}>
                              <Paper
                                p="md"
                                mb="xs"
                                bg={currentUser ? "blue.6" : "gray.3"}
                                c={currentUser ? "gray.0" : "gray.9"}
                                style={{
                                  maxWidth: "60%",
                                  wordWrap: "break-word",
                                }}>
                                <Text size="sm">{comment.content}</Text>
                              </Paper>
                            </Group>
                          </Stack>
                        ))
                      ) : (
                        <Text mb={"lg"} ta={"center"} size="sm">
                          No Comments Yet
                        </Text>
                      )}
                    </ScrollArea>
                    <form onSubmit={form.onSubmit(handleSubmitComment)}>
                      <Textarea
                        {...form.getInputProps("comment")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            form.onSubmit(handleSubmitComment)();
                          }
                        }}
                        rightSection={
                          <UnstyledButton type="submit">
                            <Kbd>↵</Kbd>
                          </UnstyledButton>
                        }
                        placeholder="Leave a comment"
                      />
                    </form>
                  </Stack>
                </Paper>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
};

export default CreateTask;
