import React, { useEffect, useState } from "react";
import { Card, Divider, Group, Stack, Text, Title } from "@mantine/core";
import { useAuth } from "../contexts/AuthContext";
import WorkSpaceGrid from "../components/Workspaces";
import axios from "axios";
import TierCard from "../components/Portal/Tiers/card";
import TierCards from "../components/Portal/Tiers/list";

const Dashboard: React.FC = () => {
  const { currentUser } = useAuth();
  const [tiers, setTiers] = useState([]);

  useEffect(() => {
    const fetchTiers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_API}/api/companies/${currentUser?.company._id}`
        );
        setTiers(response.data.tiers);
      } catch (error) {
        console.error("Failed to fetch tiers", error);
      }
    };

    if (currentUser?.company) {
      fetchTiers();
    }
  }, [currentUser]);

  return (
    <Stack gap="lg">
      <Card p="xl" shadow="none">
        <Group justify="space-between">
          <Stack gap="xs" justify="center">
            <Title fw={400} order={3}>
              👋 Welcome to your Dashboard, {currentUser.first_name}
            </Title>
            <Text size="md" color="dimmed">
              Here's what's happening with your{" "}
              {currentUser.company?.company_name} account
            </Text>
          </Stack>
        </Group>
      </Card>
      <Divider />
      <Stack p="xl" gap="xl">
        <Stack>
          <Title fw={450} order={3}>
            Your Workspaces
          </Title>
          <WorkSpaceGrid />
        </Stack>
        <Stack>
          <Title fw={450} order={3}>
            Pricing Tiers
          </Title>
          <TierCards tiers={tiers} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Dashboard;
