import React, { useState, useEffect } from "react";
import {
  Stack,
  Grid,
  Card,
  Text,
  Divider,
  ScrollArea,
  Breadcrumbs,
  Anchor,
  Image,
  Overlay,
  Center,
  RingProgress,
  ActionIcon,
  Paper,
  Title,
  Group,
  Modal,
  Button,
} from "@mantine/core";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import { Project, Workspace, Task } from "../types";
import { IconSparkles } from "@tabler/icons-react";
import GenerateProject from "../ai/Forms/Generate";
import Classes from "./Styling/Projects.module.scss"; // Assuming this is your custom CSS module
import TasksCard from "../components/Tasks/Cards/TaskCard";

const SelectProject: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { workspaceId } = useParams<{ workspaceId: string }>();

  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null
  );

  useEffect(() => {
    const fetchWorkspaces = async () => {
      if (!currentUser) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces?teamId=${currentUser._id}`
        );
        setWorkspaces(response.data);

        const workspace = response.data.find(
          (ws: Workspace) => ws._id === workspaceId
        );
        setSelectedWorkspace(workspace || null);
      } catch (error) {
        console.error("Failed to fetch workspaces", error);
      }
    };

    fetchWorkspaces();
  }, [currentUser, workspaceId]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (!workspaceId) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceId}/projects`
        );
        const projectsData = response.data;

        const projectsWithTasks = await Promise.all(
          projectsData.map(async (project: Project) => {
            const tasksResponse = await axios.get(
              `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceId}/projects/${project._id}/tasks`
            );
            return { ...project, tasks: tasksResponse.data };
          })
        );

        setProjects(projectsWithTasks);
      } catch (error) {
        console.error("Failed to fetch projects", error);
      }
    };

    fetchProjects();
  }, [workspaceId]);

  const handleProjectCreated = (newProject: any) => {
    setProjects((prevProjects) => [...prevProjects, newProject]);
  };

  const calculateCompletion = (project: Project) => {
    const tasks = project.tasks || [];
    const totalTasks = tasks.length;
    const completedTasks = tasks.filter(
      (task: Task) => task.status === "Completed"
    ).length;

    return totalTasks === 0 ? 0 : (completedTasks / totalTasks) * 100;
  };

  const items = [
    { title: "All", href: "/" },
    { title: selectedWorkspace?.title, href: "#" },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  const baseURL = process.env.REACT_APP_LIVE_API;

  function openProjectModal(): void {
    // Open the modal here
  }

  return (
    <Stack gap={0}>
      <Group px="xl" py="lg" justify="space-between">
        <Title order={4}>{selectedWorkspace?.title}</Title>
        <Group gap={6}>
          <Button variant="light">Create</Button>

          <Button
            onClick={openProjectModal}
            leftSection={<IconSparkles size={18} stroke={1.5} />}>
            Generate
          </Button>
        </Group>
      </Group>
      <Divider />

      <ScrollArea h="100%">
        <Stack>
          <Grid p={"xl"}>
            <Grid.Col span={12} mb={"md"}>
              <Breadcrumbs>{items}</Breadcrumbs>
            </Grid.Col>
            {projects.map((project) => (
              <Grid.Col
                mah={270}
                mih={270}
                key={project._id}
                span={{ xl: 3, lg: 4, md: 6, sm: 6, xs: 12 }}>
                <TasksCard
                  onDelete={(projectId) => {
                    setProjects((prevProjects) =>
                      prevProjects.filter((p) => p._id !== projectId)
                    );
                  }}
                  project={project}
                  workspaceId={workspaceId || ""}
                />
              </Grid.Col>
            ))}
            <Grid.Col
              mih={270}
              mah={270}
              span={{ xl: 3, lg: 4, md: 6, sm: 6, xs: 12 }}>
              <GenerateProject
                selectedWorkspaceId={workspaceId ?? ""}
                onProjectCreated={handleProjectCreated}
              />
            </Grid.Col>
          </Grid>
        </Stack>
      </ScrollArea>
    </Stack>
  );
};

export default SelectProject;
