import React, { useState } from "react";
import { Center, Stack, Image, Title, Text, Anchor } from "@mantine/core";
import Login from "../../components/Auths/Login";
import OnboardingForm from "../../components/Auths/Onboarding";

const LoginPage = () => {
  return (
    <Center h={"100vh"} pb="20%" bg="gray.0">
      <Stack p="xl" gap={42} maw={420}>
        <Image fit="contain" mah={72} w={"100%"} src="logo.svg" />
        <Stack ta="center" gap={6}>
          <Title>Welcome back!</Title>
          <Text size="lg">Please sign in to your account to continue.</Text>
        </Stack>
        <Login />
        <Text ta="center">
          Don't have an account?
          <Anchor href="/register" ml={4} underline="never">
            Register here.
          </Anchor>
        </Text>
      </Stack>
    </Center>
  );
};

export default LoginPage;
