import React from "react";
import { useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { Button } from "@mantine/core";
import { useAuth } from "../../../contexts/AuthContext";

const SubscribeButton: React.FC = () => {
  const stripe = useStripe();
  const { currentUser } = useAuth();

  const handleSubscribe = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LIVE_API}/api/payment/create-checkout-session`,
        {
          priceId: "price_1Pugn9JuZNRiMccrU5K4Kzfg",
          userId: currentUser._id,
        }
      );

      const { id: sessionId } = response.data;

      if (stripe) {
        const result = await stripe.redirectToCheckout({ sessionId });

        if (result.error) {
          console.error("Stripe error:", result.error.message);
        }
      } else {
        console.error("Stripe.js has not yet loaded.");
      }
    } catch (error) {
      console.error(
        "Error creating checkout session:",
        (error as Error).message
      );
    }
  };

  return (
    <Button onClick={handleSubscribe} color="blue">
      Subscribe Now
    </Button>
  );
};

export default SubscribeButton;
