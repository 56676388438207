import React, { useState } from "react";
import {
  TextInput,
  Textarea,
  Button,
  Group,
  Stack,
  Title,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import axios from "axios";
import Classes from "./index.module.scss";

const CreateWorkspaceForm = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      title: "",
    },

    validate: {
      title: (value) =>
        value.length < 2 ? "Title must be at least 2 characters" : null,
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    setLoading(true);
    try {
      const response = await axios.post("/api/workspaces", values); // Adjust the URL to your route
      if (response.data.success) {
        // Handle success (e.g., redirect to the workspace)
        window.location.reload();
      }
    } catch (error) {
      // Handle errors (e.g., display error message)
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Stack p="md" gap="xl">
        <Stack gap={4}>
          <Title order={2}>Create a new workspace</Title>
          <Text>Workspaces help you organize your tasks and projects.</Text>
        </Stack>
        <Stack>
          <TextInput
            size="lg"
            label="Workspace Name"
            placeholder="e.g. Personal Goals, Website Project..."
            {...form.getInputProps("title")}
          />

          <Group justify="right" gap="xs" mt="md">
            <Button
              className={Classes.darkButton}
              type="submit"
              loading={loading}>
              Create Workspacea
            </Button>
          </Group>
        </Stack>
      </Stack>
    </form>
  );
};

export default CreateWorkspaceForm;
