import React, { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Button, Center, Stack, Text } from "@mantine/core";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribeButton from "../Subscribe";

const CancelSubscriptionButton: React.FC = () => {
  const { currentUser } = useAuth();
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_API}/api/payment/subscription-status`,
          {
            params: { userId: currentUser._id },
          }
        );
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, [currentUser._id]);

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LIVE_API}/api/payment/cancel`,
        {
          userId: currentUser._id, // Explicitly pass userId here
        },
        {
          withCredentials: true, // Ensure that session/cookie is sent, if needed
        }
      );
      console.log("Cancellation scheduled:", response.data);
      // Update the state to reflect the cancellation
      setSubscriptionStatus((prevStatus: any) => ({
        ...prevStatus,
        cancel_at_period_end: true,
      }));
    } catch (error) {
      console.error("Error scheduling cancellation:", error);
    }
  };

  if (loading) {
    return (
      <Center>
        <Text>Loading subscription status...</Text>
      </Center>
    );
  }

  return (
    <Stack>
      {subscriptionStatus && subscriptionStatus.cancel_at_period_end ? (
        <Stack>
          <Alert title="Subscription Canceled" color="red">
            Your subscription is scheduled to be canceled at the end of the
            billing period.
          </Alert>
          <SubscribeButton />
        </Stack>
      ) : (
        <Button onClick={handleCancelSubscription} color="red">
          Cancel Subscription
        </Button>
      )}
    </Stack>
  );
};

export default CancelSubscriptionButton;
