import React, { useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const SuccessPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    console.log("Session ID:", sessionId);
    if (sessionId) {
      axios
        .get(
          `${process.env.REACT_APP_LIVE_API}/api/payment/success?session_id=${sessionId}`
        )
        .then((response) => {
          console.log("Subscription finalized:", response.data);
        })
        .catch((error) => {
          console.error("Error finalizing subscription:", error);
        });
    }
  }, [searchParams]);

  return (
    <div>
      <h1>Subscription Successful!</h1>
    </div>
  );
};

export default SuccessPage;
