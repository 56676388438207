import React, { useContext, useState, useEffect } from "react";
import axios from "../axiosConfig";

// Define the shape of your auth context
interface AuthContextType {
  currentUser: any;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (
    email: string,
    password: string,
    first_name: string,
    last_name: string
  ) => Promise<void>;
  updateCurrentUser: (user: any) => void;
  updateUser: (updates: any) => Promise<void>;
}

// Create an AuthContext with an initial undefined value
const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

// Custom hook to access the AuthContext
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

// AuthProvider component to provide authentication context to children components
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  // Check if user session is still valid
  useEffect(() => {
    axios
      .get("/api/auth/me", { withCredentials: true })
      .then((response: { data: { user: any } }) => {
        setCurrentUser(response.data.user);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setCurrentUser(null); // Session expired, user is not logged in
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // Login function
  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(
        "/api/auth/login",
        { email, password },
        { withCredentials: true }
      );
      setCurrentUser(response.data.user);
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Login failed";
      console.error("Login error:", errorMessage);
      throw new Error(errorMessage);
      // Optionally: redirect to login page or show error notification
    }
  };

  // Logout function
  const logout = async () => {
    try {
      await axios.post("/api/auth/logout", {}, { withCredentials: true }); // Matches your logout route
      setCurrentUser(null);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // Register function
  const register = async (
    email: string,
    password: string,
    first_name: string,
    last_name: string
  ) => {
    try {
      const response = await axios.post(
        "/api/auth/register", // Matches your register route
        { email, password, first_name, last_name },
        { withCredentials: true }
      );
      setCurrentUser(response.data.user);
    } catch (error: any) {
      console.error("Registration error:", error);
      throw new Error(error.response?.data?.message || "Registration failed");
    }
  };

  // Update current user details
  const updateUser = async (updates: any) => {
    if (!currentUser) return;
    try {
      const response = await axios.put(
        `/api/users/${currentUser._id}`, // Matches your user update route
        updates,
        { withCredentials: true }
      );
      setCurrentUser(response.data); // Update the current user state
    } catch (error: any) {
      console.error("Update user error:", error);
      throw new Error(error.response?.data?.message || "Update failed");
    }
  };

  // Update current user state manually
  const updateCurrentUser = (user: any) => {
    setCurrentUser(user);
  };

  const value: AuthContextType = {
    currentUser,
    login,
    logout,
    register,
    updateCurrentUser,
    updateUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}{" "}
      {/* Only render children when loading is complete */}
    </AuthContext.Provider>
  );
};

export default AuthContext;
