import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  Stack,
  Grid,
  TextInput,
  PasswordInput,
  Button,
  Alert,
} from "@mantine/core";
import {
  IconUser,
  IconMail,
  IconKey,
  IconAlertCircle,
  IconMoodSad,
} from "@tabler/icons-react";
import Classes from "./index.module.scss"; // Import the same CSS module used in Login

const Register: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Clear any previous error before attempting registration

    try {
      await register(email, password, firstName, lastName);
      navigate("/");
    } catch (error: any) {
      console.error("Failed to register", error);

      // Check for different error scenarios and provide contextual feedback
      if (error.response) {
        if (error.response.status === 400) {
          setError(
            "Uh oh! Something went wrong. Please check the details entered are correct."
          );
        } else if (error.response.status === 409) {
          setError("Email already in use. Please use a different email.");
        } else if (error.response.status === 500) {
          setError("Server error. Please try again later.");
        } else {
          setError("Failed to register. Please try again.");
        }
      } else if (error.request) {
        setError("Network error. Please check your internet connection.");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={"md"}>
        {" "}
        {/* Use the same gap as in the Login form */}
        {error && (
          <Alert
            icon={<IconMoodSad size={16} />}
            title="Something went wrong"
            color="red">
            {error}
          </Alert>
        )}
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              size="lg"
              radius="md" // Ensure consistent border radius
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              size="lg"
              radius="md" // Ensure consistent border radius
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              withAsterisk
            />
          </Grid.Col>
        </Grid>
        <TextInput
          size="lg"
          radius="md" // Ensure consistent border radius
          rightSection={<IconMail size={24} stroke={1} />}
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          withAsterisk
        />
        <PasswordInput
          size="lg"
          radius="md" // Ensure consistent border radius
          rightSection={<IconKey size={24} stroke={1} />}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          withAsterisk
        />
        <Button
          className={Classes.darkButton} // Use the same button styling
          radius="md" // Ensure consistent border radius
          type="submit"
          size="lg" // Match the size of the button
        >
          Register
        </Button>
      </Stack>
    </form>
  );
};

export default Register;
