import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  Anchor,
  Avatar,
  Burger,
  Button,
  Card,
  Divider,
  Group,
  Image,
  Indicator,
  Kbd,
  Menu,
  Paper,
  Popover,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import {
  IconArrowsLeftRight,
  IconBell,
  IconChevronDown,
  IconFile,
  IconFileInvoice,
  IconMessageCircle,
  IconPencilBolt,
  IconPhoto,
  IconRobot,
  IconSearch,
  IconSettings,
  IconSun,
  IconTrash,
  IconUserCircle,
} from "@tabler/icons-react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";

interface QuickActionBarProps {
  colorIcon?: React.ReactNode;
}

const MobileHeader: React.FC<QuickActionBarProps> = () => {
  const navigate = useNavigate();
  const { currentUser, login, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_LIVE_API}/api/auth/logout`);
      logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  useEffect(() => {
    // Optionally, you can place any additional logic you want to run when the component mounts
  }, []);

  return (
    <Group
      hiddenFrom="md"
      justify="space-between"
      align="center"
      h={60}
      px={"sm"}
    >
      <Group wrap="nowrap" gap={6}>
        <Image src={"../public/logo.svg"} height={30} />
        <Title order={4} fw={500}>
          TaskJoy
        </Title>
      </Group>

      <Group>
        <Menu position="bottom-end">
          <Menu.Target>
            <Card shadow="none" p={6} withBorder radius={"md"}>
              <Group gap={6}>
                <Avatar size={28}>{currentUser.first_name[0]}</Avatar>
                <Text size="sm" fw={500}>
                  {currentUser.first_name} {currentUser.last_name}{" "}
                </Text>
                <ActionIcon size={"sm"} variant="subtle">
                  <IconChevronDown stroke={1} size={20} />
                </ActionIcon>
              </Group>
            </Card>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>My Account</Menu.Label>
            <Menu.Item
              leftSection={
                <IconUserCircle style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Profile
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconSettings style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Settings
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconSearch style={{ width: rem(14), height: rem(14) }} />
              }
              rightSection={
                <Text size="xs" c="dimmed">
                  ⌘K
                </Text>
              }
            >
              Search
            </Menu.Item>

            <Menu.Divider />

            <Menu.Label>Logout</Menu.Label>
            <Menu.Item
              leftSection={
                <IconArrowsLeftRight
                  onClick={handleLogout}
                  style={{ width: rem(14), height: rem(14) }}
                />
              }
            >
              View as client
            </Menu.Item>
            <Menu.Item
              color="red"
              leftSection={
                <IconTrash style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Sign out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Group>
  );
};

export default MobileHeader;
