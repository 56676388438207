import React, { useState } from "react";
import {
  Card,
  Text,
  Paper,
  Stack,
  RingProgress,
  Center,
  Group,
  ActionIcon,
  Box,
  Menu,
  Modal,
  Button,
  Title,
  Image,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconCheck, IconDotsVertical } from "@tabler/icons-react";
import axios from "axios";
import { Project, Task } from "../../../../types";
import Classes from "./index.module.scss"; // Assuming you have custom styles for this component

interface ProjectCardProps {
  project: Project;
  workspaceId: string;
  onDelete: (projectId: string) => void;
}

const TasksCard: React.FC<ProjectCardProps> = ({
  project,
  workspaceId,
  onDelete,
}) => {
  const navigate = useNavigate();
  const [confirmDeleteOpened, setConfirmDeleteOpened] = useState(false);

  const calculateCompletion = (project: Project) => {
    const tasks = project.tasks || [];
    const totalTasks = tasks.length;
    const completedTasks = tasks.filter(
      (task: Task) => task.status === "Completed"
    ).length;

    return totalTasks === 0 ? 0 : (completedTasks / totalTasks) * 100;
  };

  const handleDeleteProject = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_LIVE_API}/api/workspaces/${workspaceId}/projects/${project._id}`
      );
      onDelete(project._id); // Notify the parent component about the deletion
      setConfirmDeleteOpened(false);
    } catch (error) {
      console.error("Failed to delete project", error);
    }
  };

  return (
    <>
      <Modal
        opened={confirmDeleteOpened}
        onClose={() => setConfirmDeleteOpened(false)}
        title="Delete Project">
        <Stack>
          <Title order={4}>Are you sure you want to delete this project?</Title>
          <Button onClick={handleDeleteProject} color="red">
            Delete Project
          </Button>
        </Stack>
      </Modal>

      <Card
        mah={290}
        withBorder
        className={Classes.card}
        shadow="md"
        p={1}
        radius="lg">
        <Box
          style={{
            zIndex: 100,
          }}
          pos={"absolute"}
          top={8}
          right={8}>
          <Menu zIndex={100} position="bottom-end">
            <Menu.Target>
              <ActionIcon color="dark" variant="subtle">
                <IconDotsVertical size={20} stroke={1} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => navigate(`/${project._id}`)}>
                View
              </Menu.Item>
              <Menu.Item onClick={() => navigate(`/${project._id}/edit`)}>
                Edit
              </Menu.Item>
              <Menu.Item onClick={() => setConfirmDeleteOpened(true)}>
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>

        <Paper
          radius="lg"
          onClick={() => navigate(`/${workspaceId}/projects/${project._id}`)}
          shadow="none"
          bg={"gray.1"}
          withBorder
          w={"100%"}
          h={160}>
          {!project.coverImage ? (
            <Center h={"100%"} c={"teal.1"} />
          ) : (
            <Image
              height={180}
              fit="cover"
              style={{
                zIndex: 0,
              }}
              pos="absolute"
              src={`${process.env.REACT_APP_LIVE_API}/${project.coverImage}`}
              radius="sm"
            />
          )}
        </Paper>

        <Card mt={1} py={"sm"} px={"sm"}>
          <Group wrap="nowrap" justify="space-between">
            <Stack gap={0}>
              <Text size="md" fw={600}>
                {project.title}
              </Text>
            </Stack>
            <RingProgress
              size={60}
              thickness={4}
              sections={[
                {
                  value: parseFloat(calculateCompletion(project).toFixed(2)),
                  color: calculateCompletion(project) === 100 ? "teal" : "blue",
                },
              ]}
              label={
                <Center h={"100%"} w={"100%"}>
                  {calculateCompletion(project) === 100 ? (
                    <IconCheck size={16} color="teal" stroke={2} />
                  ) : (
                    <Center w="100%">
                      <Text size="xs">
                        {`${calculateCompletion(project).toFixed(0)}%`}
                      </Text>
                    </Center>
                  )}
                </Center>
              }
            />
          </Group>
        </Card>
      </Card>
    </>
  );
};

export default TasksCard;
