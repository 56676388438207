import {
  Paper,
  Title,
  Text,
  Group,
  Divider,
  Card,
  Stack,
  Grid,
} from "@mantine/core";

interface Tier {
  _id: string;
  title: string;
  description: string;
  cpm: number;
  perks: {
    title: string;
    description: string;
  }[];
}

interface TierListProps {
  tiers: Tier[];
}

const TierList: React.FC<TierListProps> = ({ tiers }) => {
  return (
    <Stack>
      {tiers.map((tier: Tier) => (
        <Stack>
          <Paper
            style={{
              overflow: "hidden",
            }}
            withBorder
            key={tier._id}
            radius="lg">
            <Grid p={"md"}>
              <Grid.Col span={6}>
                <Title c={"dimmed"} order={6} fw={500}>
                  {tier.title}
                </Title>
                <Title order={3}>£{tier.cpm}</Title>
              </Grid.Col>
              <Grid.Col span={6}>
                <Title c={"dimmed"} order={6} fw={500}>
                  Subscribers
                </Title>
                <Title order={3}>12</Title>
              </Grid.Col>
            </Grid>
          </Paper>
        </Stack>
      ))}
    </Stack>
  );
};

export default TierList;
