import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import {
  ActionIcon,
  AppShell,
  MantineProvider,
  Modal,
  Paper,
  ScrollArea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import Navbar from "./components/SideNav";
import customTheme from "./_app";
import LoginPage from "./pages/Auth/LoginPage";
import Projects from "./pages/Tasks";
import QuickActionBar from "./components/QuickActionBar";
import PasswordPage from "./pages/Auth/SetPasswordPage";
import MobileHeader from "./components/QuickActionBar/MobileHeader";
import UserProfile from "./pages/CurrentUser/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import MyTasks from "./pages/MyTasks";
import SelectWorkspace from "./pages/SelectWorkspace";
import SelectProject from "./pages/SelectProject";
import Dashboard from "./pages/Dashboard";
import RegisterPage from "./pages/Auth/Register";
import TaskPage from "./pages/EditTask";
import ClientSettings from "./pages/ClientApp/Settings";
import UserSettings from "./pages/CurrentUser/Settings";
import OnboardingForm from "./components/Auths/Onboarding";
import { IconSun, IconMoon } from "@tabler/icons-react";
import { setupAxiosInterceptor } from "./utils/axiosSetup"; // Adjust the path
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SuccessPage from "./components/Payments/Success";

const AppShellLayout = () => {
  setupAxiosInterceptor(() => {
    console.log("Change detected via Axios interceptor");
  });

  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
  );

  const [opened, { toggle }] = useDisclosure();
  const { currentUser, updateUser } = useAuth();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [theme, setTheme] = useState<"light" | "dark">("light");

  useEffect(() => {
    const savedTheme = (localStorage.getItem("mantine-color-scheme") ||
      "light") as "light" | "dark";
    setTheme(savedTheme);
  }, []);

  useEffect(() => {
    if (currentUser && !currentUser.onboardingCompleted) {
      setShowOnboarding(true);
    } else {
      setShowOnboarding(false);
    }
  }, [currentUser]);

  const toggleTheme = () => {
    const newTheme: "light" | "dark" = theme === "light" ? "dark" : "light";
    localStorage.setItem("mantine-color-scheme", newTheme);
    setTheme(newTheme);
  };

  const handleOnboardingComplete = async (data: {
    skills: string[];
    interests: string[];
    bio: string;
  }) => {
    try {
      await updateUser({ ...data, onboardingCompleted: true });
      setShowOnboarding(false);
    } catch (error) {
      console.error("Failed to complete onboarding", error);
    }
  };

  const handleSkipOnboarding = async () => {
    try {
      await updateUser({ onboardingCompleted: true });
      setShowOnboarding(false);
    } catch (error) {
      console.error("Failed to skip onboarding", error);
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <MantineProvider theme={customTheme} forceColorScheme={theme}>
        <AppShell
          key={currentUser?._id} // Force re-render when currentUser changes
          header={{ height: 55 }}
          navbar={{
            width: 260,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}>
          <AppShell.Header
            withBorder={false}
            bg={theme === "light" ? "white" : "dark.7"}>
            <QuickActionBar />
            <MobileHeader />
          </AppShell.Header>

          <AppShell.Navbar withBorder={false}>
            <Navbar
              navColor={theme === "light" ? "white" : "dark.7"}
              colorIcon={
                <ActionIcon
                  onClick={toggleTheme}
                  size={"lg"}
                  radius={"md"}
                  variant="default">
                  {theme === "light" ? (
                    <IconSun stroke={1} size={24} />
                  ) : (
                    <IconMoon stroke={1} size={24} />
                  )}
                </ActionIcon>
              }
            />
          </AppShell.Navbar>

          <AppShell.Main h={100}>
            <AppShell.Section h={"100%"} p={6}>
              <Paper
                style={{
                  overflow: "hidden",
                }}
                withBorder
                radius="lg"
                bg={theme === "light" ? "gray.1" : "dark.6"}
                h="100%">
                <ScrollArea h="100%">
                  <Outlet />
                </ScrollArea>
              </Paper>
            </AppShell.Section>
          </AppShell.Main>

          <Modal
            opened={showOnboarding}
            onClose={handleSkipOnboarding}
            title="Complete Your Profile"
            centered
            overlayProps={{
              blur: 5,
            }}>
            <OnboardingForm
              onComplete={handleOnboardingComplete}
              onSkip={handleSkipOnboarding}
            />
          </Modal>
        </AppShell>
      </MantineProvider>
    </Elements>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <MantineProvider>
        <Router>
          <Routes>
            <Route path="/set-password" element={<PasswordPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <AppShellLayout />
                </ProtectedRoute>
              }>
              <Route path="success" element={<SuccessPage />} />
              <Route path="" element={<SelectWorkspace />} />
              <Route path="projects" element={<Projects />} />
              <Route path="account/profile" element={<UserProfile />} />
              <Route path="account" element={<UserProfile />} />
              <Route path="select-workspace" element={<SelectWorkspace />} />
              <Route path="settings/account" element={<UserSettings />} />
              <Route
                path="checkout-session/:sessionId"
                element={<SuccessPage />}
              />
              <Route
                path=":workspaceId/projects/:projectId"
                element={<Projects />}
              />
              <Route path="payments" element={<ClientSettings />} />
              <Route
                path=":workspaceId/projects/:projectId/tasks/:taskId"
                element={<TaskPage />}
              />
              <Route path=":workspaceId/assigned" element={<MyTasks />} />
              <Route path="workspaces/select" element={<Dashboard />} />
              <Route
                path="workspaces/:workspaceId/projects"
                element={<SelectProject />}
              />
              <Route path=":workspaceId/projects" element={<SelectProject />} />
            </Route>
          </Routes>
        </Router>
      </MantineProvider>
    </AuthProvider>
  );
};

export default App;
