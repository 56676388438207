import React, { useState } from "react";
import {
  Stack,
  TextInput,
  MultiSelect,
  Textarea,
  Button,
  Group,
  Modal,
  Title,
  Text,
  Input,
  SegmentedControl,
  TagsInput,
} from "@mantine/core";
import axios from "axios"; // Import axios
import { useAuth } from "../../contexts/AuthContext";
import Classes from "./index.module.scss";

interface OnboardingFormProps {
  onComplete: (data: {
    skills: string[];
    interests: string[];
    bio: string;
  }) => void;
  onSkip: () => void;
}

const OnboardingForm: React.FC<OnboardingFormProps> = ({
  onComplete,
  onSkip,
}) => {
  const { currentUser } = useAuth(); // Get the current user
  const [skills, setSkills] = useState<string[]>([]);
  const [interests, setInterests] = useState<string[]>([]);
  const [bio, setBio] = useState("");
  const [formType, setFormType] = useState("Skills");

  // State to store the selectable options
  const [skillOptions, setSkillOptions] = useState([
    "Communication",
    "Public Speaking",
    "Leadership",
    "Time Management",
    "Problem Solving",
    "Teamwork",
    "Critical Thinking",
    "Writing",
    "Editing",
    "Research",
    "Planning",
    "Negotiation",
    "Customer Service",
    "Data Entry",
    "Event Planning",
    "Organization",
    "Budgeting",
    "Financial Management",
    "Conflict Resolution",
    "Teaching",
    "Training & Development",
    "Technical Support",
    "Sales",
    "Marketing",
    "Digital Marketing",
    "Content Creation",
    "Graphic Design",
    "Video Production",
    "Web Development",
    "Software Development",
    "Data Analysis",
    "Quality Assurance",
    "Operations Management",
    "Inventory Management",
    "Logistics",
    "Administrative Support",
    "IT Support",
    "Human Resources Management",
    "Recruiting",
    "Counseling",
    "Coaching",
    "Health & Wellness",
    "Fitness Training",
    "Culinary Arts",
    "Multilingualism",
    "Translation",
    "Legal Research",
    "Advocacy",
    "Mediation",
    "Social Media Management",
  ]);

  const [interestOptions, setInterestOptions] = useState([
    "Technology",
    "Art & Design",
    "Music",
    "Sports",
    "Fitness & Exercise",
    "Outdoor Activities",
    "Reading & Writing",
    "Travel",
    "Food & Drink",
    "Science",
    "Games",
    "Adventure Travel",
    "Cultural Exploration",
    "Road Trips",
    "Eco-Tourism",
    "Cooking",
    "Baking",
    "Wine Tasting",
    "Astronomy",
    "Biology",
    "Physics",
    "Photography",
    "Film & Cinema",
    "History",
    "Philosophy",
    "Gardening",
    "Volunteering",
    "Meditation",
    "Yoga",
    "Mindfulness",
    "Environmentalism",
    "Animal Welfare",
    "DIY Projects",
    "Collecting",
    "Board Games",
    "Card Games",
    "Puzzles",
    "Theater & Performing Arts",
    "Dance",
    "Languages",
    "Writing",
    "Blogging",
    "Podcasting",
    "Entrepreneurship",
    "Startup Culture",
  ]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Make the PUT request to update the user's profile
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_API}/api/users/${currentUser._id}`,
        {
          skills,
          interests,
          bio,
          onboardingCompleted: true,
        }
      );

      onComplete(response.data); // Pass the updated user data to the onComplete callback
    } catch (error) {
      console.error("Failed to complete onboarding", error);
    }
  };

  const addNewOption = (
    query: string,
    setOptions: React.Dispatch<React.SetStateAction<string[]>>,
    setValue: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const newOption = query.trim();
    if (newOption !== "") {
      setOptions((current) => [...current, newOption]);
      setValue((current) => [...current, newOption]);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    setOptions: React.Dispatch<React.SetStateAction<string[]>>,
    setValue: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addNewOption(event.currentTarget.value, setOptions, setValue);
      event.currentTarget.value = ""; // Clear the input field
    }
  };

  return (
    <Modal
      size="lg"
      centered
      overlayProps={{
        blur: 10,
      }}
      closeOnClickOutside={false}
      radius="lg"
      withCloseButton={false}
      opened={true} // Keep the modal opened
      onClose={onSkip}>
      <form onSubmit={handleSubmit}>
        <Stack p="xl" gap="xl">
          <Stack gap={8}>
            <Title order={2}>Tell us a little bit about yourself</Title>
            <Text>
              This will help Joy break down projects into easy tasks tailored to
              your skills and interests.
            </Text>
          </Stack>

          <Stack gap="lg">
            <Textarea
              size="lg"
              labelProps={{
                mb: 0,
              }}
              descriptionProps={{
                mb: 12,
                lh: 1.6,
              }}
              placeholder="Tell us about yourself"
              description="Introduce yourself, your interests, and what you're looking to achieve."
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              label="Bio"
              required
              minRows={8}
            />
            <TagsInput
              data={skillOptions}
              size="lg"
              placeholder="Select or enter your skills"
              description="Select your skills to help Joy match you with the right tasks."
              labelProps={{
                mb: 0,
              }}
              descriptionProps={{
                mb: 12,
                lh: 1.6,
              }}
              value={skills}
              onChange={setSkills}
              label="Skills"
              required
              onKeyDown={(event) =>
                handleKeyDown(event, setSkillOptions, setSkills)
              }
            />
            <TagsInput
              data={interestOptions}
              size="lg"
              placeholder="Select your interests"
              description="Select your interests to help Joy make your tasks more fun."
              labelProps={{
                mb: 0,
              }}
              descriptionProps={{
                mb: 12,
                lh: 1.6,
              }}
              value={interests}
              onChange={setInterests}
              label="Interests"
              required
              onKeyDown={(event) =>
                handleKeyDown(event, setInterestOptions, setInterests)
              }
            />
          </Stack>

          <Group justify="space-between" gap="xs">
            <Button variant="subtle" color="gray" onClick={onSkip}>
              I'll do it later
            </Button>
            <Button className={Classes.darkButton} type="submit">
              Complete Onboarding
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default OnboardingForm;
